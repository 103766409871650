export class NotificationsResponse {
  id: string;
  member: MemberData;
  message: Messages[];
  dateTime: any;
  recipient: MemberData;
  notificationType: string;
  read: boolean;
  hidden: boolean;
  plan: string = "";
}
export class MemberData {
  memberKey: string;
  firstName: string;
  lastName: string;
}
export class Messages {
  language: string;
  content: string;
  links: Links[] = [];
}
export class Links {
  title: string;
  url: string;
}
export class UpdateNotificationRequest {
  MemberKey: string;
  constructor(MemberKey: string) {
    this.MemberKey = MemberKey;
  }
  notificationsResponseList: NotificationsResponse[];
}
export class UpdateNotificationResponse {
  statusCode: number;
  responseBody: object
}
export class NotificationResponseList {
  notificationsResponseList: NotificationsResponse[];
}
export class NotificationRequest {
  MemberKey: string;
  ProviderId: string;
  constructor(MemberKey: string, ProviderId: string) {
    this.MemberKey = MemberKey;
    this.ProviderId = ProviderId;
  }
}
