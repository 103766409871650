import { FindADoctorComponent } from 'libs/components/find-care/find-a-doctor.component';
import { FeatureFlagGuard } from 'libs/services/feature-flag.service';
import { FlexibleTemplateComponent } from 'libs/templates/flexible/flexible.component';
import { FlexibleTwoColTemplateComponent } from 'libs/templates/flexible-two-col/flexible-two-col.component';

export const AmazonRoutes = [

  // AMAZON: HOME
  { path: '', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'home-amazon', contentGroup: 'member-home-amazon' } },

  // AMAZON: BENEFITS & COVERAGE
  { path: 'welcome', component: FlexibleTemplateComponent, data: { pageWidth:'full', page: 'welcome-amazon', contentGroup: 'member-welcome-amazon' } },
  { path: 'benefit-summary', component: FlexibleTemplateComponent, data: { pageWidth:'full', page: 'benefit-summary-amazon-v2', contentGroup: 'member-benefit-summary-amazon' } },
  { path: 'preapproval', component: FlexibleTemplateComponent, data: { pageWidth:'full', page: 'preapproval-amazon', contentGroup: 'member-preapproval-amazon' } },
  { path: 'personal-health-support', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'personal-health-support-amazon', contentGroup: 'member-personal-health-support-amazon' } },
  { path: 'personal-funding-accounts', component: FlexibleTemplateComponent, data: { pageWidth:'full', page: 'personal-funding-accounts-amazon', contentGroup: 'member-personal-funding-accounts-amazon' } },
  { path: 'personal-funding-accounts/health-savings-accounts',  component: FlexibleTemplateComponent, data: { pageWidth:'full', page: 'health-savings-accounts-amazon', contentGroup: 'member-health-savings-accounts-amazon' } },
  { path: 'coverage-while-traveling', component: FlexibleTemplateComponent, data: { page: 'coverage-while-traveling-amazon', contentGroup: 'member-coverage-while-traveling-amazon' } },
  { path: 'new', component: FlexibleTemplateComponent, data: { pageWidth:'full', page: 'new-amazon', contentGroup: 'member-new-amazon' } },

  // AMAZON: PRESCRIPTIONS
  { path: 'manage-prescriptions', component: FlexibleTemplateComponent, data: { page: 'manage-prescriptions-amazon', contentGroup: 'member-manage-prescriptions-amazon' } },

  // AMAZON: FIND CARE
  { path: 'find-a-doctor', component: FindADoctorComponent, data: { feature: 'findADoctor', pageWidth:'full', page: 'find-a-doctor-amazon', contentGroup: 'member-find-a-doctor-amazon' }, canActivate: [FeatureFlagGuard] },
  { path: 'decision-aids', component: FlexibleTemplateComponent, data: { page: 'decision-aids-amazon', contentGroup: 'member-decision-aids-amazon' } },
  { path: 'virtual-care', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'virtual-care-amazon', contentGroup: 'member-virtual-care-amazon' } },

  // AMAZON: EXPLORE RESOURCES: QUICK HELP
  { path: 'quick-help', component: FlexibleTwoColTemplateComponent, data: { page: 'quick-help-amazon', contentGroup: 'member-quick-help-amazon' } },
  { path: 'quick-help/claims', component: FlexibleTwoColTemplateComponent, data: { page: 'claims-amazon', contentGroup: 'member-claims-amazon' } },
  { path: 'quick-help/benefits', component: FlexibleTwoColTemplateComponent, data: { page: 'benefits-amazon', contentGroup: 'member-benefits-amazon' } },
  { path: 'quick-help/care-costs', component: FlexibleTwoColTemplateComponent, data: { page: 'care-costs-amazon', contentGroup: 'member-care-costs-amazon' } },
  { path: 'quick-help/accounts-id-cards', component: FlexibleTwoColTemplateComponent, data: { page: 'accounts-id-cards-amazon', contentGroup: 'member-accounts-id-cards-amazon' } },
  { path: 'quick-help/medications', component: FlexibleTwoColTemplateComponent, data: { page: 'medications-amazon', contentGroup: 'member-medications-amazon' } },
  { path: 'quick-help/plan-basics', component: FlexibleTwoColTemplateComponent, data: { page: 'plan-basics-amazon', contentGroup: 'member-plan-basics-amazon' } },
  { path: 'quick-help/policies-practices', component: FlexibleTwoColTemplateComponent, data: { page: 'policies-practices-amazon', contentGroup: 'member-policies-practices-amazon' } },

  // AMAZON: EXPLORE RESOURCES: CARE ESSENTIALS
  { path: 'care-essentials', component: FlexibleTwoColTemplateComponent, data: { page: 'care-essentials-amazon', contentGroup: 'member-care-essentials-amazon' } },
  { path: 'care-essentials/preventive-care', component: FlexibleTwoColTemplateComponent, data: { page: 'preventive-care-amazon', contentGroup: 'member-preventive-care-amazon' } },
  { path: 'care-essentials/substance-abuse', component: FlexibleTwoColTemplateComponent, data: { page: 'substance-abuse-amazon', contentGroup: 'member-substance-abuse-amazon' } },
  { path: 'care-essentials/womens-health', component: FlexibleTwoColTemplateComponent, data: { page: 'womens-health-amazon', contentGroup: 'member-womens-health-amazon' } },
  { path: 'care-essentials/mens-health', component: FlexibleTwoColTemplateComponent, data: { page: 'mens-health-amazon', contentGroup: 'member-mens-health-amazon' } },
  { path: 'care-essentials/kids-health', component: FlexibleTwoColTemplateComponent, data: { page: 'kids-health-amazon', contentGroup: 'member-kids-health-amazon' } },
  { path: 'care-essentials/lgbt-health', component: FlexibleTwoColTemplateComponent, data: { page: 'lgbt-health-amazon', contentGroup: 'member-lgbt-health-amazon' } },
  { path: 'care-essentials/mental-health', component: FlexibleTwoColTemplateComponent, data: { page: 'mental-health-amazon', contentGroup: 'member-mental-health-amazon' } },
  { path: 'care-essentials/cancer', component: FlexibleTwoColTemplateComponent, data: { page: 'cancer-amazon', contentGroup: 'member-cancer-amazon' } },
  { path: 'care-essentials/diabetes', component: FlexibleTwoColTemplateComponent, data: { page: 'diabetes-amazon', contentGroup: 'member-diabetes-amazon' } },
  { path: 'care-essentials/pregnancy', component: FlexibleTwoColTemplateComponent, data: { page: 'pregnancy-amazon', contentGroup: 'member-pregnancy-amazon' } },
  { path: 'care-essentials/newborns', component: FlexibleTwoColTemplateComponent, data: { page: 'newborns-amazon', contentGroup: 'member-newborns-amazon' } },
  { path: 'care-essentials/family-planning-sexual-health', component: FlexibleTwoColTemplateComponent, data: { page: 'family-planning-sexual-health-amazon', contentGroup: 'member-family-planning-sexual-health-amazon' } },
  { path: 'care-essentials/screenings', component: FlexibleTwoColTemplateComponent, data: { page: 'screenings-amazon', contentGroup: 'member-screenings-amazon' } },
  { path: 'care-essentials/sleep-conditions', component: FlexibleTwoColTemplateComponent, data: { page: 'sleep-conditions-amazon', contentGroup: 'member-sleep-conditions-amazon' } },
  { path: 'care-essentials/back-pain', component: FlexibleTwoColTemplateComponent, data: { page: 'back-pain-amazon', contentGroup: 'member-back-pain-amazon' } },

  //  AMAZON: EXPLORE RESOURCES: OTHER.
  { path: 'forms', component: FlexibleTemplateComponent, data: { page: 'forms-amazon', contentGroup: 'member-forms-amazon' } },
  { path: 'glossary', component: FlexibleTemplateComponent, data: { page: 'glossary-amazon', contentGroup: 'member-glossary-amazon' } },
  { path: 'go-mobile', component: FlexibleTemplateComponent, data: { page: 'go-mobile-amazon', contentGroup: 'member-go-mobile-amazon' } },
  { path: 'health-equity', component: FlexibleTemplateComponent, data: {  pageWidth: 'full', page: 'health-equity-amazon', contentGroup: 'member-health-equity-amazon' } },
  { path: 'how-health-plans-work', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'how-health-plans-work-amazon', contentGroup: 'member-how-health-plans-work-amazon' } },
  { path: 'provider-networks', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'provider-networks-amazon', contentGroup: 'member-provider-networks-amazon' } },

  //  AMAZON: ABOUT US
  { path: 'premera-difference', component: FlexibleTemplateComponent, data: { page: 'premera-difference-amazon', contentGroup: 'member-premera-difference-amazon' } },
  { path: 'about-premera', component: FlexibleTemplateComponent, data: { page: 'about-premera-amazon', contentGroup: 'member-about-premera-amazon' } },
  { path: 'executives', component: FlexibleTemplateComponent, data: { page: 'executives-amazon', contentGroup: 'member-executives-amazon' } },
  { path: 'companies-we-work-with', component: FlexibleTemplateComponent, data: { page: 'companies-we-work-with-amazon', contentGroup: 'member-companies-we-work-with-amazon' } },
  { path: 'community-support', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'community-support-amazon', contentGroup: 'member-community-support-amazon' } },

  // AMAZON: GUIDANCE
  { path: 'data-collection', component: FlexibleTemplateComponent, data: { page: 'data-collection-amazon', contentGroup: 'member-data-collection-amazon' } },
  { path: 'fraud-abuse', component: FlexibleTemplateComponent, data: { page: 'fraud-abuse-amazon', contentGroup: 'member-fraud-abuse-amazon' } },
  { path: 'privacy-practices', component: FlexibleTemplateComponent, data: { page: 'privacy-practices-amazon', contentGroup: 'member-privacy-practices-amazon' } },
  { path: 'aviso-practicas-privacidad', component: FlexibleTemplateComponent, data: { page: 'aviso-practicas-privacidad-amazon', contentGroup: 'member-aviso-practicas-privacidad-amazon' } },
  { path: 'terms-conditions', component: FlexibleTemplateComponent, data: { page: 'terms-conditions-amazon', contentGroup: 'member-terms-conditions-amazon' } },
  { path: 'medical-policies', component: FlexibleTemplateComponent, data: { page: 'medical-policies-amazon', contentGroup: 'member-medical-policies-amazon' } },

  // AMAZON: GET CONNECTED
  { path: 'contact-us', component: FlexibleTemplateComponent, data: { pageWidth: 'full', page: 'contact-us-amazon', contentGroup: 'member-contact-us-amazon' } },

  // AMAZON: OTHER
  { path: 'outbreaks', component: FlexibleTemplateComponent, data: { page: 'outbreaks-amazon', contentGroup: 'member-outbreaks-amazon' } },
  { path: 'natural-disasters', component: FlexibleTemplateComponent, data: { page: 'natural-disasters-amazon', contentGroup: 'member-natural-disasters-amazon' } },
  { path: 'flu', component: FlexibleTemplateComponent, data: { page: 'flu-amazon', contentGroup: 'member-flu-amazon' } }
];


